<template>
  <div class="pubWarehouse">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>民间智库</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-form
        class="el__form"
        ref="form"
        size="small"
        label-suffix=":"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item prop="targetId" label="建言领域">
          <el-select
            v-model="form.targetId"
            placeholder="请选择建言领域"
            clearable
          >
            <el-option
              v-for="(item, index) in type"
              :key="index"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="userName" label="昵称">
          <el-input v-model="form.userName" clearable placeholder="请输入昵称">
          </el-input>
        </el-form-item>
        <el-form-item prop="userAge" label="年龄">
          <el-input v-model="form.userAge" placeholder="请输入年龄"> </el-input>
        </el-form-item>
        <el-form-item prop="userWork" label="职业">
          <el-input v-model="form.userWork" placeholder="请输入职业">
          </el-input>
        </el-form-item>
        <el-form-item prop="userPhone" label="手机">
          <el-input v-model="form.userPhone" placeholder="请输入手机">
          </el-input>
        </el-form-item>
        <el-form-item prop="title" label="标题">
          <el-input
            v-model="form.title"
            :maxlength="40"
            placeholder="不超过40字，请勿填写特殊字符"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="content" label="">
          <el-input
            type="textarea"
            :autosize="{ minRows: 7 }"
            v-model="form.content"
            placeholder="请输入建言"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="subBtnLoad"
            class="el__btn"
            @click="submit('form')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getSelect, add } from "@/api/zk";
export default {
  data() {
    return {
      form: {},
      subBtnLoad: false,
      type: [],
      rules: {
        targetId: [
          { required: true, message: "请选择建言领域", trigger: "change" },
        ],
        userName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        userAge: [{ required: true, validator: this.age, trigger: "blur" }],
        userWork: [{ required: false, message: "请输入职业", trigger: "blur" }],
        userPhone: [{ required: true, validator: this.phone, trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入建言", trigger: "blur" }],
      },
    };
  },
  created() {
    getSelect().then((res) => {
      console.log(res.data);
      this.type = res.data;
    });
  },
  methods: {
    phone(rule, value, callback) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的手机号"));
      }
    },
    age(rule, value, callback) {
      const reg = /^[0-9]*$/;
      if (value === "") {
        callback(new Error("请输入年龄"));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的格式"));
      }
    },
    submit(formName) {
      this.subBtnLoad = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          add(this.form)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.msg);
                this.$router.push("/web/suggest");
              } else {
                this.subBtnLoad = false;
              }
            })
            .catch(() => {
              this.subBtnLoad = false;
            });
        } else {
          this.subBtnLoad = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pubWarehouse {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .location {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .content {
    padding: 80px 0 180px;
    .el__form {
      margin-top: 24px;
      .el-input,
      .el-select,
      .el-autocomplete {
        width: 600px;
      }
      .el__btn {
        width: 156px;
        background: #5cbd31;
        border: 0;
        color: #ffffff;
      }
    }
  }
}
</style>
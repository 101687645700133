import request from '../utils/request';

export const getSelect = () => request({
    url: '/api/blade-zk/event/select',
    method: 'get',
})


export function add(data) {
	return request({
		url: '/api/blade-zk/essay/front/add',
		method: 'post',
		data
	})
}